import React from 'react';
import {
  Row, Col, Form, Input, Button, message, notification,
} from 'antd';
import { observer, inject } from 'mobx-react';
import getAgentInstance from '../../helpers/superagent';

const superagent = getAgentInstance();
@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      data: [],
      loading: false,
    });
    this.state = this.initialState();

    this.onFinish = (values) => {
      this.setState({ loading: true });
      superagent
        .post('/v1/auth/login')
        .send(values)
        .end((err, res) => {
          if (err) {
            notification.error({ content: 'Login Failed!' });
          } else {
            const { body } = res;
            const { tokenStore, userStore } = this.props;
            if (body.data.token) {
              userStore.value = body.data.user;
              tokenStore.value = body.data.token;
              window.location.reload();
              message.success({ content: 'Successfully logged in' });
            } else {
              message.error({ content: body.status });
            }
          }
          this.setState({ loading: false });
        });
    };
  }

  render() {
    const { loading } = this.state;

    return (
      <Form layout="vertical" onFinish={this.onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={4} offset={20}>
            <Button htmlType="submit" block type="primary" loading={loading}>
              Login
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Login;
