import { lazy } from 'react';

const options = [
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../landing/land')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user',
    component: lazy(() => import('../user/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/booking',
    component: lazy(() => import('../booking/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/confirmed_booking',
    component: lazy(() => import('../confirmed_booking/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/delivered_booking',
    component: lazy(() => import('../delivered_booking/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/cancled_booking',
    component: lazy(() => import('../cancled_booking/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/about',
    component: lazy(() => import('../about/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/service',
    component: lazy(() => import('../service/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contact',
    component: lazy(() => import('../contact/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/notification',
    component: lazy(() => import('../notification/List')),
    exact: true,
  },
];
export default options;
