import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import {
  Layout, Menu, Button, Col, Popover, Avatar, List, Modal as AntdModal,
} from 'antd';
import {
  MenuOutlined,
  UserOutlined,
  ReadOutlined,
  PhoneOutlined,
  ProfileOutlined,
  SettingOutlined,
  BellOutlined,
} from '@ant-design/icons';

import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';
import Loading from '../basic/Loading';
import routes from './dashboardRoutes';
import largeLogo from '../../assets/images/kernel.png';
import smallLogo from '../../assets/images/kernel.png';
import './dashboard.css';
import getAgentInstance from '../../helpers/superagent';

const superagent = getAgentInstance();

const { Header, Content, Sider } = Layout;

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      collapsed: false,
      expandedWithToggler: true,
      imageId: '',
      access: JSON.parse(this.props.userStore.value.privilege),
    });
    this.state = this.initialState();
    this.onCollapse = (collapsed) => {
      this.setState({ collapsed });
    };
    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
        expandedWithToggler: true,
      }));
    };
    this.expandSidebar = () => {
      const { collapsed } = this.state;
      if (collapsed) {
        this.setState({ collapsed: false, expandedWithToggler: false });
      }
    };
    this.collapseSidebar = () => {
      const { expandedWithToggler } = this.state;
      if (!expandedWithToggler) this.setState({ collapsed: true });
    };
    this.logout = () => {
      Promise.all([
        // eslint-disable-next-line react/destructuring-assignment
        this.props.userStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.tokenStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.sectionsStore.clear(),
      ]).then(() => {});
    };
  }

  componentDidMount() {
    const { userStore } = this.props;
    if (userStore.value.privilege === undefined) {
      this.setState({ access: {} });
    }
    // superagent.get('/v1/user/me').end((err, res) => {
    //   console.log(err);
    //   // if (!err) {
    //   //   const { body } = res;
    //   //   console.log('this is body getme', body);

    //   //   userStore.value = body;
    //   // } else if (err && err.status === 401) {
    //   //   AntdModal.error({
    //   //     centered: true,
    //   //     title: 'Unauthorised!',
    //   //     content: 'This account is unauthorised, You need to logout!',
    //   //     okText: 'Procced',
    //   //     onOk() {
    //   //       scope.logout();
    //   //     },
    //   //   });
    //   // }
    // });
  }

  render() {
    const { collapsed, access } = this.state;
    const { location, userStore } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <style>
          {`
          .ant-menu-item-selected{
            background-color: #1f2533 !important;
          }
        `}
        </style>
        <Sider
          theme="dark"
          onMouseEnter={this.expandSidebar}
          onMouseLeave={this.collapseSidebar}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          style={{
            height: '100vh',
            left: 0,
            position: 'fixed',
            overflow: 'auto',
            background: '#2d3546',
          }}
          width={240}
        >
          <div
            className="logo"
            style={{
              minHeight: 70,
              textAlign: 'center',
              paddingTop: 20,
              background: '#1f2532',
            }}
          >
            {collapsed ? (
              <img
                alt="lucid-logo"
                style={{ width: 45, marginBottom: 5 }}
                src={smallLogo}
              />
            ) : (
              <img alt="lucid-logo" style={{ width: 140 }} src={largeLogo} />
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ background: '#2d3646', marginTop: 35 }}
          >

            {access['View User'] && (
            <Menu.Item key="/user">
              <UserOutlined />
              <span>Users</span>
              <Link to="/user" />
            </Menu.Item>
            )}

            {access['View Booking'] && (
            <Menu.SubMenu
              key="booking"
              icon={<ReadOutlined />}
              title="Booking Info"
            >
              <Menu.Item key="/booking">
                <ReadOutlined />
                <span>Pendding Booking</span>
                <Link to="/booking" />
              </Menu.Item>
              <Menu.Item key="/confirmed_booking">
                <ReadOutlined />
                <span>Confirmed Booking</span>
                <Link to="/confirmed_booking" />
              </Menu.Item>
              <Menu.Item key="/delivered_booking">
                <ReadOutlined />
                <span>Delivered Booking</span>
                <Link to="/delivered_booking" />
              </Menu.Item>
              <Menu.Item key="/cancled_booking">
                <ReadOutlined />
                <span>Cancled Booking</span>
                <Link to="/cancled_booking" />
              </Menu.Item>
            </Menu.SubMenu>
            )}

            {access['View Contact'] && (
            <Menu.Item key="/contact">
              <PhoneOutlined />
              <span>Contact</span>
              <Link to="/contact" />
            </Menu.Item>
            )}

            {access['View About'] && (
            <Menu.Item key="/about">
              <ProfileOutlined />
              <span>About Us</span>
              <Link to="/about" />
            </Menu.Item>
            )}

            {access['View Service'] && (
            <Menu.Item key="/service">
              <SettingOutlined />
              <span>Services</span>
              <Link to="/service" />
            </Menu.Item>
            )}

            {access['View Notification'] && (
            <Menu.Item key="/notification">
              <BellOutlined />
              <span> Send Notification</span>
              <Link to="/notification" />
            </Menu.Item>
            )}

          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: 'all 0.2s',
            marginLeft: collapsed ? 80 : 240,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: 'inherit' }}
          >
            <Col span={1} style={{ textAlign: 'center' }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col offset={21} span={2}>
              <Popover
                content={(
                  <List>
                    <List.Item>
                      <a href="# " onClick={this.logout}>
                        Logout
                      </a>
                    </List.Item>
                  </List>
                )}
                trigger="hover"
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Avatar src="https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png" />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
