import React from 'react';
import {
  Row, Col, Card, Typography,
} from 'antd';
import { observer, inject } from 'mobx-react';
import sideImage from '../../assets/images/side2.jpg';
import Login from './Login';

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({});

    this.state = this.initialState();
  }

  render() {
    const { tokenStore, to } = this.props;
    if (`${tokenStore.value}`.trim() !== '') {
      return to;
    }
    return (
      <Row
        style={{ position: 'absolute', height: '100%', width: '100%' }}
        type="flex"
        align="middle"
      >
        <Col
          style={{
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url('${sideImage}')`,
            backgroundPosition: 'center',
            boxShadow: '1px 3px 9px #dde4e7',
          }}
          span={15}
        >
          <Row
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            type="flex"
            align="middle"
          />
        </Col>
        <Col span={9} offset={0}>
          <Row>
            <Col span={24}>
              <Typography.Title
                align="center"
                color="primary"
                style={{ color: '#00008b', letterSpacing: '1.5px' }}
              >
                <span style={{ color: '#000000' }}>Diwan Lounge Dashboard</span>
                {' '}
              </Typography.Title>
            </Col>
            <Col style={{ marginLeft: '20px' }} span={22} justify="end">
              <Card bordered={false}>
                <Login />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LoginManager;
